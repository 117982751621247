<template>
  <link :href="require('@/assets/css/materialicon.css')" rel="stylesheet" />

  <f7-page class="createpost-page">
    <template #fixed>
      <ProfileNavigationComponent :title="pageTitle" type="back" :settings="false" />
    </template>

    <section class="createpost-container">
      <div class="container">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_MEDIA") }}</h1>
        </div>

        <div class="posts-container">
          <f7-list
            v-if="formData?.PostMediaList?.length > 0"
            media-list
            :sortable="!isUpdate"
            sortable-enabled
            data-sortable-move-elements="false"
            @sortable:sort="onSortMedia($event)"
          >
            <f7-list-item
              v-for="(media, index) in formData?.PostMediaList"
              :key="'pm_' + index"
              :title="media.type"
            >
              <template v-if="!isUpdate" #after-title>
                <f7-button fill small color="red" @click="onRemoveMedia(index)">
                  <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                </f7-button>
              </template>
              <template #subtitle> {{ media?.file?.name || media?.FileName }}</template>
              <template #text>
                {{ $h.formatFileSize(media?.file?.size || media?.Size) }}
              </template>
              <template #media>
                <img
                  v-if="media?.type === 'IMAGE' && !media?.PostMediaId"
                  :src="$h.renderImageBlob(media?.blob)"
                  loading="lazy"
                />
                <img
                  v-if="media?.Type === 'IMAGE' && media?.PostMediaId"
                  :src="$h.getImage(media?.FileName, 'POST')"
                  loading="lazy"
                />

                <video
                  v-if="media?.type === 'VIDEO' || media?.Type === 'VIDEO'"
                  autoplay
                  muted
                >
                  <source
                    v-if="media?.PostMediaId > 0"
                    :src="$h.getVideo(media?.FileName, 'POST')"
                    :type="media?.MimeType || 'video/mp4'"
                  />
                  <source
                    v-else
                    :src="media?.source"
                    :type="media?.file?.type || 'video/mp4'"
                  />

                  {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
                </video>
              </template>
            </f7-list-item>
          </f7-list>
          <div v-if="!isUpdate" class="create-post">
            <f7-button fill large @click="onSelectMedia">{{
              $t.getTranslation("LBL_SELECT_MEDIA")
            }}</f7-button>
          </div>
        </div>

        <template v-if="hasPosterSelection">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_POSTER") }}</h1>
          </div>

          <f7-swiper :speed="500" :slidesPerView="3" :spaceBetween="20">
            <f7-swiper-slide
              v-for="(media, index) in posterList"
              :key="'pm_' + index"
              @click="selectPoster(media)"
            >
              <div
                class="poster-selection"
                :style="{
                  'background-image': 'url(' + $h.getPoster(media?.poster, true) + ')',
                }"
                :class="{ selected: formData.PostVideoPoster == media?.poster }"
              ></div>
            </f7-swiper-slide>
          </f7-swiper>
        </template>

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_CONTENT") }}</h1>
        </div>

        <div class="form-create">
          <f7-list id="formContent" form no-hairlines>
            <f7-list-input
              v-model:value="formData.PostTitle"
              name="PostTitle"
              :label="$t.getTranslation('LBL_POST_TITLE')"
              :required="validationRules?.PostTitle?.required"
              :minlength="validationRules?.PostTitle?.minimumLength"
              :maxlength="validationRules?.PostTitle?.maximumLength"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="
                $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
              "
              outline
              floating-label
              type="text"
              validate
              clear-button
            >
            </f7-list-input>

            <f7-text-editor
              v-model:value="formData.PostHighlight"
              name="PostHighlight"
              :dividers="false"
              :label="$t.getTranslation('LBL_POST_HIGHLIGHT')"
              :placeholder="$t.getTranslation('LBL_POST_HIGHLIGHT')"
              :error-message="
                $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
              "
              outline
              floating-label
              validate
              clear-button
              @texteditor:change="(v) => (formData.PostHighlight = v)"
            >
            </f7-text-editor>

            <f7-text-editor
              v-model:value="formData.PostText"
              name="PostText"
              :dividers="false"
              :label="$t.getTranslation('LBL_POST_CONTENT')"
              :placeholder="$t.getTranslation('LBL_POST_CONTENT')"
              :error-message="
                $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
              "
              outline
              floating-label
              validate
              clear-button
              @texteditor:change="(v) => (formData.PostText = v)"
            >
            </f7-text-editor>
          </f7-list>
        </div>

        <div v-if="postMode != 'tastingrecord'" class="post-product-container">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_PRODUCTS") }}</h1>
            <f7-button fill @click="selectProduct">{{
              $t.getTranslation("LBL_ADD_PRODUCT")
            }}</f7-button>
          </div>

          <div
            v-if="formData?.ProductList && formData?.ProductList.size > 0"
            class="product-container"
          >
            <template
              v-for="product of formData.ProductList"
              :key="'prd_' + product.ProductKey"
            >
              <ProductListCardComponent
                :data="product"
                type="select"
                @selected="removeProduct"
                :isShop="true"
              />
            </template>
          </div>

          <NoDataFoundComponent
            v-else
            :size="'sm'"
            :title="$t.getTranslation('LBL_PRODUCTS_EMPTY')"
          />
        </div>

        <f7-button
          fill
          large
          preloader
          class="post-button"
          :loading="isButtonProcessing"
          :disabled="isButtonProcessing || isDisableNextButton"
          @click="next"
        >
          <template v-if="postMode === 'tastingrecord'">
            {{ $t.getTranslation("LBL_POST_TASTING_RECORD") }}
          </template>
          <template v-else>
            {{
              isUpdate ? $t.getTranslation("LBL_UPDATE") : $t.getTranslation("LBL_POST")
            }}
          </template>
        </f7-button>
      </div>
    </section>

    <MediaUploadPopUpComponent
      :open="openPopup"
      :image-size-limit="5"
      :video-size-limit="videoSizeLimit"
      @closed="onClosedPopup"
      @selected="onMediaSelected"
    />

    <ProductSelectionPopUpComponent
      :kol="true"
      :open="openProductPopup"
      @closed="onCloseProductPopup"
      @selected="onProductSelected"
    />
    <PostTranslationPopUpComponent
      :open="openPostTranslationPopup"
      :data="selectedPostContent"
      @closed="onClosePostTranslationPopup"
      @saved="onPostContentSaved"
    />
  </f7-page>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  computed,
  inject,
  onMounted,
  defineAsyncComponent,
} from "vue";
import { f7 } from "framework7-vue";
import _ from "lodash";
import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { configs, extendedConfigs } from "@/utils/configs.js";
import { useStore } from "@/store";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import MediaUploadPopUpComponent from "@/components/MediaUploadPopUpComponent.vue";
// import ProductSelectionPopUpComponent from "@/components/ProductSelectionPopUpComponent.vue";
// import PostTranslationPopUpComponent from "@/components/PostTranslationPopUpComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"
  )
);
const ProductListCardComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"
  )
);
const MediaUploadPopUpComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "media-upload-popup" */ /* webpackMode: "lazy" */ "@/components/MediaUploadPopUpComponent.vue"
  )
);
const ProductSelectionPopUpComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "product-select-popup" */ /* webpackMode: "lazy" */ "@/components/ProductSelectionPopUpComponent.vue"
  )
);
const PostTranslationPopUpComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "post-translate-popup" */ /* webpackMode: "lazy" */ "@/components/PostTranslationPopUpComponent.vue"
  )
);
const NoDataFoundComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"
  )
);

export default defineComponent({
  name: "CreatePostPage",
  components: {
    ProfileNavigationComponent,
    MediaUploadPopUpComponent,
    ProductSelectionPopUpComponent,
    PostTranslationPopUpComponent,
    NoDataFoundComponent,
    ProductListCardComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const userInfo = computed(() => store.getters["user/getData"]);
    const isPjfOfficial = computed(() => store.getters["user/isPjfOfficial"]);
    const postMediaUploadLimit = computed(
      () => store.getters["config/getData"]?.postMediaUploadLimit
    );

    const postMode = ref("");

    const isUpdate = ref(false);
    const pageTitle = ref("");
    let hasPosterSelection = ref(false);
    let posterList = ref([]);

    const isButtonProcessing = ref(false);
    const videoSizeLimit = ref(extendedConfigs.videoSizeLimit);

    const formData = reactive({
      PostType: "POST",
      PostTitle: "",
      PostHighlight: "",
      PostText: "",
      PostVideoPoster: "",
      PostMediaList: [],
      TranslationList: new Set(),
      DeleteTranslationList: new Set(),
      ProductList: new Set(),
      DeleteProductList: new Set(),
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const getPostData = async (key) => {
      let postViewReturn = await get("/post/view", {
        PostKey: key,
        LanguageCode: $t.getLanguage(),
      });

      for (let keys of [
        "PostType",
        "PostTitle",
        "PostHighlight",
        "PostText",
        "PostKey",
      ]) {
        if (postViewReturn && postViewReturn[keys]) {
          formData[keys] = postViewReturn[keys];
        }
      }

      formData.TranslationList = new Set();
      formData.DeleteTranslationList = new Set();

      formData.PostMediaList = postViewReturn?.PostMediaList;

      if (postViewReturn?.TranslationList) {
        for (let translation of postViewReturn?.TranslationList) {
          formData.TranslationList.add(translation);
        }
      }

      formData.ProductList = new Set();
      formData.DeleteProductList = new Set();

      if (postViewReturn?.ProductList) {
        for (let product of postViewReturn?.ProductList) {
          formData.ProductList.add(product);
        }
      }
    };

    onMounted(() => {
      if (props?.f7route?.params?.key && props?.f7route?.params?.key != "") {
        isUpdate.value = true;
        pageTitle.value = $t.getTranslation("LBL_UPDATE_POST");
        getPostData(props?.f7route?.params?.key);
      } else {
        isUpdate.value = false;
        pageTitle.value = $t.getTranslation("LBL_CREATE_POST");
      }

      if (props?.f7route?.query?.mode) {
        postMode.value = props?.f7route?.query?.mode;
        pageTitle.value = $t.getTranslation("LBL_CREATE_TASTING_RECORD");
      }

      let increaseUserVideoLimit = ["pjfwines"];
      if (
        userInfo?.value?.UserName &&
        increaseUserVideoLimit.indexOf(userInfo?.value?.UserName) > -1
      ) {
        videoSizeLimit.value = 215;
      }
    });

    const setPostType = (postType) => {
      if (!isUpdate.value) formData.PostType = postType;
    };

    const openPopup = ref(false);

    const onClosedPopup = () => {
      openPopup.value = false;
    };

    const onSelectMedia = (type) => {
      openPopup.value = true;
    };

    const selectPoster = (item) => {
      if (formData.PostVideoPoster == item.poster) {
        return (formData.PostVideoPoster = "");
      }
      formData.PostVideoPoster = item.poster;
    };

    const checkPoster = () => {
      hasPosterSelection.value = posterList.value.length ? true : false;
      let found = _.find(posterList.value, (r) => {
        return r.poster == formData.PostVideoPoster;
      });
      if (formData.PostVideoPoster && !found) {
        formData.PostVideoPoster = "";
      }
    };

    const onRemoveMedia = (index) => {
      let media = formData.PostMediaList[index];

      posterList.value = _.filter(posterList.value, (r) => {
        return r?.file != media?.file?.name;
      });
      checkPoster();

      formData.PostMediaList.splice(index, 1);
    };

    const onSortMedia = ({ from, to }) => {
      formData["PostMediaList"].splice(
        to,
        0,
        formData["PostMediaList"].splice(from, 1)[0]
      );
    };

    const onMediaSelected = async (data) => {
      for (let item of data) {
        formData.PostMediaList.push(item);
      }

      if (formData?.PostMediaList?.length > postMediaUploadLimit.value) {
        formData.PostMediaList.splice(0, 1);
      }

      //get timemarks now
      let videos = _.filter(data, (r) => {
        return r.type == "VIDEO";
      });

      if (videos.length) {
        videos = [videos[0]]; //first video only
        //hasPosterSelection.value = (videos.length) ? true : false
        for (let video of videos) {
          let timeData = new FormData();
          timeData.append(`video`, video?.file);

          let ret = await post(`/video/timemarks`, timeData);
          for (let file of ret.filenames) {
            posterList.value.push({
              file: video?.file?.name,
              poster: file,
            });
          }
        }
      }

      //submit now
      checkPoster();
    };

    const openPostTranslationPopup = ref(false);
    const selectedPostContent = ref(false);

    const addContent = () => {
      openPostTranslationPopup.value = true;
    };

    const onClosePostTranslationPopup = () => {
      openPostTranslationPopup.value = false;
    };

    const onPostContentSaved = (data) => {
      for (let item of formData.TranslationList) {
        if (item.LanguageCode === data.LanguageCode) {
          formData.TranslationList.delete(item);
          break;
        }
      }

      formData.TranslationList.add(data);
    };

    const postContentActions = (data) => {
      selectedPostContent.value = data;
      f7.sheet.get(".postContentActions").open();
    };

    const editPostContent = () => {
      openPostTranslationPopup.value = true;
    };

    const deletePostContent = () => {
      if (
        selectedPostContent.value?.PostTranslationId &&
        selectedPostContent.value?.PostTranslationId > 0
      ) {
        helpers.createConfirmation({
          message: $t.getTranslation("LBL_CONFIRM_DELETE_POST_CONTENT"),
          confirm: async () => {
            formData.DeleteTranslationList.add(selectedPostContent.value);
            formData.TranslationList.delete(selectedPostContent.value);
          },
        });
      } else {
        formData.TranslationList.delete(selectedPostContent.value);
      }
    };

    const openProductPopup = ref(false);

    const onCloseProductPopup = () => {
      openProductPopup.value = false;
    };

    const selectProduct = () => {
      openProductPopup.value = true;
    };

    const onProductSelected = (data) => {
      let isAlreadySelected = false;
      for (let item of formData.ProductList) {
        if (item.ProductKey === data.ProductKey) {
          isAlreadySelected = true;
          break;
        }
      }

      if (!isAlreadySelected) formData.ProductList.add(data);
    };

    const removeProduct = (data) => {
      if (data?.PostProductId && data?.PostProductId > 0) {
        helpers.createConfirmation({
          message: $t.getTranslation("LBL_CONFIRM_DELETE_POST_PRODUCT"),
          confirm: async () => {
            formData.DeleteProductList.add(data);
            formData.ProductList.delete(data);
          },
        });
      } else {
        formData.ProductList.delete(data);
      }
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const next = async () => {
      let isValid = validate(true);

      if (isValid) {
        formData.TranslationList.add({
          LanguageCode: $t.getLanguage(),
          PostTitle: formData.PostTitle,
          PostHighlight: formData.PostHighlight,
          PostText: formData.PostText,
        });

        let postSaveReturn;
        if (isUpdate.value === true) {
          formData.TranslationList = Array.from(formData.TranslationList).map((item) => {
            return {
              LanguageCode: item.LanguageCode,
              PostTitle: item.PostTitle,
              PostHighlight: item.PostHighlight,
              PostText: item.PostText,
            };
          });

          formData.ProductList = Array.from(formData.ProductList).map((item) => {
            return {
              ProductKey: item.ProductKey,
              Position: item.Position || 0,
            };
          });

          formData.DeleteTranslationList = Array.from(formData.DeleteTranslationList).map(
            (item) => item.PostTranslationId
          );
          formData.DeleteProductList = Array.from(formData.DeleteProductList).map(
            (item) => item.PostProductId
          );

          postSaveReturn = await post(`/post/update`, formData);
        } else {
          const postFormData = new FormData();

          for (let formItem in formData) {
            if (formItem === "ProductList") {
              let productList = [];
              formData[formItem].forEach((item) => {
                productList.push({
                  ProductKey: item.ProductKey,
                  Position: item.Position || 0,
                });
              });

              postFormData.append(formItem, JSON.stringify(productList));
            } else if (formItem === "TranslationList") {
              let translationList = [];
              formData[formItem].forEach((item) => {
                translationList.push({
                  LanguageCode: item.LanguageCode,
                  PostTitle: item.PostTitle,
                  PostHighlight: item.PostHighlight,
                  PostText: item.PostText,
                });
              });

              postFormData.append(formItem, JSON.stringify(translationList));
            } else if (formItem === "DeleteTranslationList") {
            } else if (formItem === "DeleteProductList") {
            } else if (formItem === "PostMediaList") {
              let tmpData = [];

              formData["PostMediaList"].forEach((item, index) => {
                if (item.type === "IMAGE") {
                  if (item?.blob) {
                    postFormData.append(`Image-${index + 1}`, item?.blob);
                  } else {
                    postFormData.append(`Image-${index + 1}`, item?.file);
                  }
                } else if (item.type === "VIDEO") {
                  postFormData.append(`Video-${index + 1}`, item?.file);
                } else {
                  tmpData.push(item);
                }
              });

              postFormData.append(formItem, JSON.stringify(tmpData));
            } else if (formItem === "PostType") {
              if (postMode.value != "") {
                postFormData.append("PostType", "TASTINGRECORD");
              } else {
                postFormData.append(formItem, formData[formItem]);
              }
            } else {
              postFormData.append(formItem, formData[formItem]);
            }
          }

          postSaveReturn = await post(`/post/create`, postFormData);
        }

        if (postSaveReturn) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message:
              isUpdate.value === true
                ? $t.getTranslation("LBL_INFO_SUCCESS_UPDATE_POST")
                : $t.getTranslation("LBL_INFO_SUCCESS_CREATE_POST"),
          });

          props.f7router.back({ force: true });
        }
      }
    };

    return {
      pageTitle,
      isUpdate,
      isPjfOfficial,
      videoSizeLimit,
      setPostType,
      postMode,
      formData,
      validationRules,
      openPopup,
      onClosedPopup,
      onSelectMedia,
      onRemoveMedia,
      onSortMedia,
      onMediaSelected,
      openPostTranslationPopup,
      addContent,
      selectedPostContent,
      onClosePostTranslationPopup,
      onPostContentSaved,
      postContentActions,
      editPostContent,
      deletePostContent,
      openProductPopup,
      onCloseProductPopup,
      selectProduct,
      onProductSelected,
      removeProduct,
      isDisableNextButton,
      isButtonProcessing,
      next,
      hasPosterSelection,
      posterList,
      selectPoster,
    };
  },
});
</script>

<style scoped>
.poster-selection-container {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
}
.poster-selection {
  border: 2px solid transparent;
  border-radius: 3px;

  width: 100%;
  background-position: center center;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 100%;
  cursor: pointer;
}
.poster-selection.selected {
  border-color: #0a4e8e;
}
</style>
